import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Ecuador",
  "subtitle": "December 2011",
  "category": "South America"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul">{`Be careful of pickpockets and your surroundings`}</li>
    </ul>
    <h2 {...{
      "id": "quito"
    }}>{`Quito`}</h2>
    <ul>
      <li parentName="ul">{`Bull fighting is been banned but for historic context, there was the `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Plaza_de_toros_de_Quito"
        }}>{`Plaza de toros de Quito`}</a></li>
    </ul>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/dAVdZvp6d8jN6maE8"
        }}>{`Crepes & Waffles`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/1FaSHvb6u5kEaQvz8"
        }}>{`Zazu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/tR6TyAcpqVnprGyN9"
        }}>{`KFC`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Was the largest fast-food chain in Ecuador when I was there`}</li>
          <li parentName="ul">{`Try crushed ice milkshake called “Krushers”`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Mei Lai Wah - Was curious about the Chinese food but can't recommend it...`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/eU3KkQWdifrHWjGf7"
        }}>{`TelefériQo`}</a>{` - Gondola lift view was great`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.quicentro.com/"
        }}>{`Quicentro`}</a>{` - Modern shopping mall (when I was there)`}</li>
    </ul>
    <h5 {...{
      "id": "the-equator"
    }}>{`The Equator`}</h5>
    <ul>
      <li parentName="ul">{`Long-standing debate on which is the true center; modern science vs old heritage`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/XPS8A6n8wWKRsQUw9"
        }}>{`Museo de Sitio Intiñan`}</a>{` - Known as the “Middle of the World” where you can learn to balance an egg. English guided tour recommended.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/iZY3tA83q7S2uzvDA"
        }}>{`Ciudad Mitad del Mundo`}</a>{` - Monument`}</li>
    </ul>
    <h2 {...{
      "id": "galápagos-islands"
    }}>{`Galápagos Islands`}</h2>
    <ul>
      <li parentName="ul">{`My trip was a cruise since licensed guide required for most parts`}</li>
      <li parentName="ul">{`Snorkeling was great. Glass-bottom boat was the alternative if one doesn't swim.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/SGBsv9E57sBwetcs9"
        }}>{`Charles Darwin Research Station`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Lonesome_George"
            }}>{`Lonesome George`}</a>{` passed away in 2012 :(`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "guayaquil"
    }}>{`Guayaquil`}</h2>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul">{`Most of the restaurants I tried have closed`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/PzvkwjqseuxAVnRN8"
        }}>{`Sweet & Coffee`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/SkkB7z3NqMgnAgzdA"
        }}>{`Parque de las Iguanas`}</a>{` - So many iguanas`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/8P6qpcTcBdHUbC5A8"
        }}>{`Las Penas`}</a>{` - Lots of stairs but also very picturesque`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://goo.gl/maps/6tKKd1x83xp8zrcS7"
            }}>{`Cerro Santa Ana`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/aKyVXm8ZY9mJEmEY6"
        }}>{`Malecón 2000`}</a>{` - The boardwalk is next to the Guayas River`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      